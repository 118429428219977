import React from 'react';
import evelektrikarizalari from '../images/evelektrikarizalari.webp';
import elektrikliarac from '../images/elektrikliarac.webp';
import elektrikprojelerivetadilat from '../images/elektrikprojelerivetadilat.webp';
import kamerasistemleriarizalari from '../images/kamerasistemleriarizalari.webp';
import goruntuvediyafonsistemleri from '../images/goruntuvediyafonsistemleri.webp';
import alarmveyanginsistemleri from '../images/alarmveyanginsistemleri.webp';

const Services = () => {

    return (
        <div id="services" className="bg-gray-100 py-12" >
            <section data-aos="zoom-in-down">
                    <div className="my-4 py-4">
                        <h2 className="my-2 text-center text-3xl text-blue-900 font-bold">HİZMETLER</h2>
                        
                        <div className='flex justify-center'>
                            <div className='w-24 border-b-4 border-blue-900'></div>
                        </div>
                        <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-semibold text-blue-900">Enerji ve Güvenlikte En İyi Çözüm Ortağınız!</h2>
                    </div>

                    <div className="px-12" data-aos="fade-down" data-aos-delay="600">
                        <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-5 mb-3">
                            
                            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                                <div className="m-2 text-justify text-sm">
                                <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={evelektrikarizalari} />
                                    <h2 className="font-semibold my-4 text-2xl text-center">Ev Elektrik Arızaları</h2>
                                    <p className="text-md font-medium">
                                    Evde yaşanan elektrik sorunlarına hızlı ve güvenilir çözümler sunuyoruz. Alanında uzman ekibimiz, priz değişiminden sigorta sorunlarına, aydınlatma tamirinden kablolama ihtiyaçlarına kadar her türlü elektrik arızasında en kaliteli hizmeti sağlamak için yanınızda. Güvenliğiniz ve konforunuz için daima güncel teknolojilerle çalışıyor, çözüm odaklı yaklaşımlar sunuyoruz.
                                    </p>
                                </div>
                            </div>

                            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                                <div className="m-2 text-justify text-sm">
                                <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={elektrikliarac} />
                                    <h2 className="font-semibold my-4 text-2xl text-center">Elektrikli Araç Altyapı Hizmetleri</h2>
                                    <p className="text-md font-medium">
                                    Elektrikli araçların kullanımının artmasıyla birlikte, bu araçlar için gerekli altyapıyı sağlamak adına profesyonel çözümler sunuyoruz. Otoparklara elektrik hattı çekme hizmetimizle, elektrikli araçların güvenli ve verimli bir şekilde şarj edilmesini mümkün kılıyoruz. Uzman ekibimiz, ihtiyaçlarınıza özel tasarlanmış elektrik hatları ile otopark alanlarınızı optimize ederken, sürdürülebilir ulaşım çözümlerinin bir parçası olmanıza yardımcı oluyor. Hem bireysel kullanıcılar hem de işletmeler için güvenilir ve pratik elektrikli araç altyapı hizmetleri sunuyoruz.
                                    </p>
                                </div>
                            </div>

                            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                                <div className="m-2 text-justify text-sm">
                                    <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={elektrikprojelerivetadilat} />
                                    <h2 className="font-semibold my-4 text-2xl text-center ">Elektrik Projeleri ve Tadilat</h2>
                                    <p className="text-md font-medium">
                                    Gelişen ihtiyaçlarınızı karşılamak ve yaşam alanlarınızı daha verimli hale getirmek için kapsamlı elektrik projeleri ve tadilat hizmetleri sunuyoruz. Uzman ekibimiz, yeni elektrik sistemlerinin kurulumu, mevcut sistemlerin modernizasyonu ve tadilat süreçlerinde sizlere profesyonel destek sağlar. İhtiyaçlarınıza özel projelerle, enerji verimliliğini artıran, güvenli ve sürdürülebilir çözümler sunarak yaşam alanlarınızı optimize ediyoruz. Kaliteli malzeme kullanımı ve titiz işçilikle, projelerinizi en yüksek standartlarda gerçekleştiriyoruz.
                                    </p>
                                </div>
                            </div>                
                        </div>
                        <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-5">
                            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                                <div className="m-2 text-justify text-sm">
                                <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={alarmveyanginsistemleri} />
                                    <h2 className="font-semibold my-4 text-2xl text-center ">Alarm ve Yangın Sistemleri</h2>
                                    <p className="text-md font-medium">
                                    Güvenliğiniz için kritik öneme sahip alarm ve yangın sistemlerinizi en üst düzeyde koruyoruz. Profesyonel ekibimiz, alarm sistemlerinizdeki bağlantı sorunları ve sensör arızaları ile yangın güvenlik sistemlerinizdeki duman dedektörleri ve sprinkler kontrollerini hızlı bir şekilde tespit eder ve onarımlarını gerçekleştirir. Tüm sistemlerinizi düzenli olarak kontrol ederek, olası riskleri minimize ediyor ve güvenliğinizi artırıyoruz.
                                    </p>
                                </div>
                            </div>
                            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                                <div className="m-2 text-justify text-sm">
                                <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={kamerasistemleriarizalari} />
                                    <h2 className="font-semibold my-4 text-2xl text-center ">Kamera Sistemleri Arızaları</h2>
                                    <p className="text-md font-medium">
                                    Kamera sistemlerinizde yaşanan aksaklıklar için profesyonel destek sağlıyoruz. Görüntü kaybı, bağlantı sorunları veya teknik aksaklıklar gibi durumlarda hızlı müdahalemizle sisteminizi en kısa sürede eski performansına kavuşturuyoruz. Güvenliğinizi aksatmamak için kaliteli yedek parçalar ve ileri teknoloji kullanarak kamera arızalarınıza kalıcı çözümler sunuyoruz.
                                    </p>
                                </div>
                            </div>
                            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                                <div className="m-2 text-justify text-sm">
                                <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={goruntuvediyafonsistemleri} />
                                    <h2 className="font-semibold my-4 text-2xl text-center ">Görüntü ve Diyafon Sistemleri Kurulum</h2>
                                    <p className="text-md font-medium">
                                    Güvenliğinizi artırmak ve iletişiminizi kolaylaştırmak amacıyla modern görüntü ve diyafon sistemleri kurulum hizmeti sunuyoruz. Uzman ekibimiz, yaşam alanlarınızda veya iş yerlerinizde ihtiyaçlarınıza uygun görüntü sistemleri ile interkom çözümleri sağlar. Yüksek çözünürlüklü kameralar ve kullanıcı dostu diyafon sistemleri ile ziyaretçilerinizle kolayca iletişim kurmanızı sağlarken, güvenlik düzeyinizi artırıyoruz. Tüm kurulum süreçlerini profesyonel bir şekilde yöneterek, sistemlerinizin en iyi performansla çalışmasını sağlıyoruz.
                                    </p>
                                </div>
                            </div> 
                        </div>
                    </div>
            </section>
            {/*
            <section>
                <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6">
                    <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="zoom-out">
                        <div className="lg:w-1/2 flex flex-col lg:mx-4 justify-center">
                            <div className='text-blue-900 mb-4'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="72" height="72" viewBox="0 0 24 24" className='fill-current'><path d="M2 12h2a7.986 7.986 0 0 1 2.337-5.663 7.91 7.91 0 0 1 2.542-1.71 8.12 8.12 0 0 1 6.13-.041A2.488 2.488 0 0 0 17.5 7C18.886 7 20 5.886 20 4.5S18.886 2 17.5 2c-.689 0-1.312.276-1.763.725-2.431-.973-5.223-.958-7.635.059a9.928 9.928 0 0 0-3.18 2.139 9.92 9.92 0 0 0-2.14 3.179A10.005 10.005 0 0 0 2 12zm17.373 3.122c-.401.952-.977 1.808-1.71 2.541s-1.589 1.309-2.542 1.71a8.12 8.12 0 0 1-6.13.041A2.488 2.488 0 0 0 6.5 17C5.114 17 4 18.114 4 19.5S5.114 22 6.5 22c.689 0 1.312-.276 1.763-.725A9.965 9.965 0 0 0 12 22a9.983 9.983 0 0 0 9.217-6.102A9.992 9.992 0 0 0 22 12h-2a7.993 7.993 0 0 1-.627 3.122z"></path><path d="M12 7.462c-2.502 0-4.538 2.036-4.538 4.538S9.498 16.538 12 16.538s4.538-2.036 4.538-4.538S14.502 7.462 12 7.462zm0 7.076c-1.399 0-2.538-1.139-2.538-2.538S10.601 9.462 12 9.462s2.538 1.139 2.538 2.538-1.139 2.538-2.538 2.538z"></path></svg>
                            </div>
                            <h3 className="text-3xl  text-blue-900 
                            font-bold">We <span className='font-black'>Build</span></h3>
                            <div>
                                <p className='my-3 text-xl text-gray-600 font-semibold'>
                                    With over 10 years experience in software analysis and design and a deep understanding of the latest IT trends and solutions, we provide customized recommendations and strategies to help you improve your operations, reduce costs, and increase efficiency.
                                </p>
                            </div>
                        </div>
                        <div className="lg:w-1/2 flex flex-col lg:mx-4 justify-center">
                            <div className='text-blue-900 mb-4'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="72" height="72" viewBox="0 0 24 24" className='fill-current'><path d="m7.375 16.781 1.25-1.562L4.601 12l4.024-3.219-1.25-1.562-5 4a1 1 0 0 0 0 1.562l5 4zm9.25-9.562-1.25 1.562L19.399 12l-4.024 3.219 1.25 1.562 5-4a1 1 0 0 0 0-1.562l-5-4zm-1.649-4.003-4 18-1.953-.434 4-18z"></path></svg>
                            </div>
                            <h3 className="text-3xl  text-blue-900 font-bold">We <span className='font-black'>Collaborate</span></h3>
                            <div>
                                <p className='my-3 text-xl text-gray-600 font-semibold'>We can collaborate with your existing tech team to scale existing software applications or design customized software applications that suits your everyday need and simplifies various processes.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            */
            }
        </div>
    )
}

export default Services;